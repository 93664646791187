<template>
  <div class="sim-ui">
    <div class="sim-ui__anker-btns">
      <a href="#" class="sim-ui__anker-btn" @click.prevent="ankerJump(0)">
        <i>
          <img :src="`${$store.state.imgDir}viewer/icon-face.svg`" alt="" />
          <span>3</span>
        </i>
        <p>フェイス</p>
      </a>
      <a href="#" class="sim-ui__anker-btn" @click.prevent="ankerJump(3)">
        <i>
          <img :src="`${$store.state.imgDir}viewer/icon-hand.svg`" alt="" />
          <span>3</span>
        </i>
        <p>ハンド</p>
      </a>
      <a href="#" class="sim-ui__anker-btn" @click.prevent="ankerJump(6)">
        <i>
          <img :src="`${$store.state.imgDir}viewer/icon-item.svg`" alt="" />
          <span>2</span>
        </i>
        <p>アイテム</p>
      </a>
    </div>
    <div class="sim-ui-list">
      <i class="sim-ui-list__arrow"></i>
      <Slider @drop-item="dropItem" ref="slider" />
      <div class="sim-ui-list__footer">
        <div class="sim-ui-list__drag-label">
          <img :src="`${$store.state.imgDir}viewer/drag-label.png`" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '../molecules/SliderMol.vue'

export default {
  data(){
    return {
    }
  },
  components: {
    Slider,
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  unmounted () {
  },
  methods:{

    ankerJump (_idx) {
      this.$refs.slider.goto(_idx)      
    },

    dropItem(item, dropPos) {
      this.isItemDragging = false
      this.$emit('drop-item', item, dropPos);
    },

    updateItem(param) {
      this.resetItem(param.key)
      this.selectItem(param.key, param.val)
    },

    selectItem(key,val) {
      const id = key+val
      if(this.$refs[id]){
        // this.$refs[id][0].select()
      }
    },

    resetItem(key) {
      const lastIdx = this.$store.state.selectedParts[key]
      const id = key+lastIdx
      if(this.$refs[id]){
        // this.$refs[id][0].return()
      }
    },

  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixin";
.sim-ui {
  position: relative;
  width: 100%;
  margin: 0 auto;

  &__anker-btns {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: spvw(100);

    @include onPC {
      position: absolute;
      width: pcperw(80, 1000);
      right: pcperw(40, 1000);
      bottom: 100%;
      flex-direction: column;
      margin-bottom: pcperw(60, 1000);
    }
  }

  &__anker-btn {
    position: relative;
    display: block;
    width: spvw(120);    
    @include iconBtn;
    margin: 0 spvw(20);
    flex: 0 0 auto;

    @include onPC {
      width: 100%;
      margin: pcperw(40, 80) 0 0;
    }
  }

  &-list {
    position: relative;
    width: 100%;
    background: url($imgDir+'viewer/ui-bg.png') 50% 0 repeat;
    background-size: spvw(57);
    padding: 0 spvw(30) ;

    &::after {
      @include pseudo;
      @include full;
      pointer-events: none;
      box-sizing: border-box;
      
      border: {
        top: spvw(4) solid;
        bottom: spvw(4) solid;
      }
      border-image: url($imgDir+'viewer/ui-frame.png') 30 / spvw(30) 0 spvw(30) 0 round;
    }

    &__arrow {
      position: absolute;
      width: spvw(84);
      left: 50%;
      top: 0;
      transform: translate3d(-50%, -50%, 0);
      z-index: 1;

      &::after {
        @include pseudo(relative);
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border-radius: 50%;
        background: url($imgDir+'viewer/icon-drag-arrow.svg') 50% 50% no-repeat $grey;
        background-size: 100%;
      }

    }

    &__footer {
      position: relative;
      width: 100%;
      margin-top: -2%;
      transform: translate3d(0, 46%, 0);
      z-index: 1;
    }

    &__drag-label {
      position: relative;
      width: spvw(470);
      margin: 0 auto;
    }

    @include onPC {
      background-size: pcvw(57);
      padding: 0 pcvw(40) ;

      &::after {
        border: pcvw(4) solid;
        border-image: url($imgDir+'viewer/ui-frame.png') 30 / pcvw(30) 0 pcvw(30) 0 round;
      }

      &__arrow {
        width: pcperw(60, 1000);
      }

      &__footer {
      }

      &__drag-label {
        width: pcperw(340, 1000);
      }
    }

    @include onWide {
      background-size: pcpx(57);
      padding: 0 pcpx(40) ;

      &::after {
        border: pcpx(4) solid;
        border-image: url($imgDir+'viewer/ui-frame.png') 30 / pcpx(30) 0 pcpx(30) 0 round;
      }
    }
  }
}
</style>