import { createStore } from 'vuex'

export default createStore({
  state: {
    // breakpoint (pictureタグに使用)
    bp: 'min-width: 768px',

    // 画像ディレクトリ
    imgDir: './simulator/img/', // 本番
    // imgDir: './img/',

    // ドラッグエリア($refオブジェクト)
    dragArea: null,

    // PartsID
    partsId: [
      'face',
      'right',
      'left',
      'hands',
    ],

    // パーツリスト
    itemsStatus: [{
        parts: 'face',
        index: 1,
        label: '通常顔',
      },
      {
        parts: 'face',
        index: 2,
        label: '笑顔',
      },
      {
        parts: 'face',
        index: 3,
        label: '見上げ顔',
      },
      {
        parts: 'right',
        index: 1,
        label: '右手（通常）',
      },
      {
        parts: 'left',
        index: 1,
        label: '左手（通常）',
      },
      {
        parts: 'hands',
        index: 1,
        label: '両手組み',
      }, {
        parts: 'right',
        index: 2,
        label: '音符エフェクト',
      }, {
        parts: 'left',
        index: 2,
        label: 'パッケージ',
      },
    ],

    // 初期各パーツindex
    defaultParts: {
      face: 1,
      right: 1,
      left: 1,
      hands: 0,
    },

    // 選択中の各パーツindex
    selectedParts: {
      face: 1,
      right: 1,
      left: 1,
      hands: 0,
    },

    // ドロップしたときにフィットする座標(割合)
    fitPer: {
      face: {
        x: 0.5,
        y: 0.35,
      },
      right: {
        x: 0.4,
        y: 0.6,
      },
      left: {
        x: 0.6,
        y: 0.6,
      },
      hands: {
        x: 0.5,
        y: 0.6,
      },
    }
  },
  getters: {
  },
  mutations: {
    setViewerImage(state, ref) {
      state.viewerImage = ref
    },

    updateParts(state, {
      parts,
      index
    }) {
      state.selectedParts[parts] = index
    },
  },
  actions: {
  },
  modules: {
  }
})
