import * as PIXI from 'pixi.js'
// import {Tween24, Ease24} from 'tween24'

let texture

export default class EffectStar extends PIXI.Sprite {

  static init(_tx) {
    texture = _tx
  }

	constructor() {
		super(texture);
		this.anchor.x = 0.5
		this.anchor.y = 0.5
		this.width = 85
		this.height = 85

    this.clear()
	}

  play() {
    const rad = Math.random() * 2 * Math.PI
    const startR = Math.random() * 50 + 50
    const speed = Math.random() * 3 + 1
    this.x = Math.cos(rad) * startR
    this.y = Math.sin(rad) * startR
    this.xSpeed = Math.cos(rad) * speed
    this.ySpeed = Math.sin(rad) * speed
    this.s = Math.random() * 0.7 + 0.3
    this.sSpeed = Math.random() * 0.04 + 0.95
    this.scale.x = this.scale.y = this.s
    this.rSpeed = Math.random() * 2 - 1
    this.angle = Math.random() * 360
    this.g = Math.random() * 0.3 + 0.95
    this.visible = true
  }

  update(dlt) {
    this.ySpeed += this.g * dlt * 0.01
    this.x += this.xSpeed
    this.y += this.ySpeed
    this.s *= this.sSpeed
    this.scale.x = this.scale.y = this.s
    this.angle += this.rSpeed

    this.alpha = 1 - this.y / 250

    return (this.alpha <= 0)
  }


  clear() {
    this.visible = false
  }

}
