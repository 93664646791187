<template>
  <div class="sim-ui-slider" ref="slider">
    <div class="sim-ui-slider__drag-trg" ref="drag_trg"
      :style="dragTrgStyle"
      @mousedown.prevent="dragStart"
      @touchstart.prevent="dragStart">
      <div class="sim-ui-slider__item" v-for="item in $store.state.itemsStatus" :key="`${item.parts}${item.index}`" ref="items">
        <UIParts :ref="`${item.parts}${item.index}`" :param="item"
          @drag-start="itemDragStart"
          @drop-item="dropItem" />
      </div>
    </div>
    <a class="sim-ui-slider__arrow be--l" href="#" @click.prevent="nextPrev(-1)">
      <img :src="`${$store.state.imgDir}viewer/slider-arrow-l.svg`" alt="" />
    </a>
    <a class="sim-ui-slider__arrow be--r" href="#" @click.prevent="nextPrev(1)">
      <img :src="`${$store.state.imgDir}viewer/slider-arrow-r.svg`" alt="" />
    </a>
  </div>
</template>

<script>
import UIParts from './UIPartsMol.vue'

export default {
  data(){
    return {
      x: 0,
      xto: 0,
      lastMouseX: 0,
      isDragging: false,
      isAutoGoto: false,
      speed: 0,
      mouseDlt: 0,
    }
  },
  components: {
    UIParts,
  },
  computed: {
    dragTrgStyle () {
      return {
        transform: 'translate3d(' + this.x + 'px, 0, 0)'
      }
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this.loop()
  },
  unmounted () {
  },
  methods:{
    itemDragStart() {
      this.dragReset()
    },

    dropItem(item, dropPos) {
      this.$emit('drop-item', item, dropPos);
    },

    dragStart(event) {
      this.speed = 0
      this.isDragging = true
      const mpos = this.getMousePos(event)
      this.xto = this.x
      this.isAutoGoto = false
      this.lastMouseX = mpos.x;
      window.addEventListener('mousemove', this.dragMove)
      window.addEventListener('mouseup', this.dragEnd)
      window.addEventListener('touchmove', this.dragMove)
      window.addEventListener('touchend', this.dragEnd)
    },

    dragMove(event) {
      const mpos = this.getMousePos(event)
      this.mouseDlt = mpos.x - this.lastMouseX;
      this.x += this.mouseDlt
      this.lastMouseX = mpos.x
    },

    dragEnd() {
      this.speed = this.mouseDlt
      this.dragReset()
    },

    dragReset() {
      this.isDragging = false
      window.removeEventListener('mousemove', this.dragMove)
      window.removeEventListener('mouseup', this.dragEnd)
      window.removeEventListener('touchmove', this.dragMove)
      window.removeEventListener('touchend', this.dragEnd)
    },

    getMousePos(event) {
      let x, y
      const touches = event.changedTouches;
      if (touches) {
        x = touches[0].clientX;
        y = touches[0].clientY;
      } else {
        x = event.clientX;
        y = event.clientY;
      }
      return ({ x, y })
    },

    loop () {
      const areaW = this.$refs.slider.clientWidth
      const trgW = this.$refs.drag_trg.clientWidth
      const max = trgW - areaW

      if(!this.isDragging && trgW > areaW) {

        if (this.isAutoGoto) {
          this.x += (this.xto - this.x) * 0.1
          if (Math.abs(this.xto - this.x) < 0.5) {
            this.x = this.xto
            this.isAutoGoto = false
          }
        } else {
          this.speed *= 0.85
          this.x += this.speed
        }

        if(this.x > 0) {
          this.isAutoGoto = false
          this.speed = 0
          this.x += (0 - this.x) * 0.2
        } else if (this.x < -max) {
          this.isAutoGoto = false
          this.speed = 0
          this.x += (-max - this.x) * 0.2
        }
      }

      requestAnimationFrame(this.loop)
    },

    goto(_idx) {
      const idx = Math.max(0, Math.min(_idx, this.$refs.items.length-1))
      const areaW = this.$refs.slider.clientWidth
      const trgW = this.$refs.drag_trg.clientWidth
      const max = trgW - areaW

      const sliderPos = this.$refs.slider.getBoundingClientRect()
      const itemPos = this.$refs.items[idx].getBoundingClientRect()
      const dlt = itemPos.left - sliderPos.left
      
      this.isAutoGoto = true
      this.xto = Math.min(0, Math.max(-max, this.x - dlt))

    },

    nextPrev(_np) {
      const itemW = this.$refs.items[0].clientWidth
      const nowX = (this.isAutoGoto) ? this.xto : this.x
      if (_np > 0) {
        this.goto(Math.floor(-nowX / itemW) + 1)
      } else if(Math.abs(nowX % itemW) > itemW / 2){
        this.goto(Math.floor(-nowX / itemW))
      } else {
        this.goto(Math.floor(-nowX / itemW) - 1)
      }

    }

  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixin";

.sim-ui-slider {
  position: relative;
  width: 100%;
  height: spvw(280);
  z-index: 2;

  &__drag-trg {
    position: absolute;
    left: 0;
    top: spvw(60);
    display: flex;
  }

  &__arrow {
    display: block;
    position: absolute;
    top: 50%;
    width: spvw(32);
    transform: translate3d(0, -50%, 0);

    &.be--l {
      left: -4%;
    }
    &.be--r {
      right: -4%;
    }
  }

  @include onPC {
    height: pcvw(280);

    &__drag-trg {
      top: pcvw(60);
    }

    &__arrow {
      width: pcvw(46);
    }
  }

  @include onWide {
    height: pcpx(280);

    &__drag-trg {
      top: pcpx(60);
    }

    &__arrow {
      width: pcpx(46);
    }
  }
}
</style>