<template>
  <section id="simulator" class="sim-wrap">
    <div class="sim-inner">
      <h2 class="sim__title">
        <picture>
          <source :srcset="`${$store.state.imgDir}viewer/title-pc.png`" :media="`(${$store.state.bp})`">
          <img :src="`${$store.state.imgDir}viewer/title.png`" alt="" />
        </picture>
      </h2>
      <SimulatorOrg ref="main"></SimulatorOrg>
      <div class="sim__warning">
        <span>
          <img :src="`${$store.state.imgDir}viewer/warning.png`" alt="" />
        </span>
      </div>
    </div>
  </section>
  <!--/.sim-wrap-->
</template>

<script>
import SimulatorOrg from './components/organisms/SimulatorOrg.vue'

export default {
  name: 'App',
  components: {
    SimulatorOrg
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixin";
@import "@/assets/scss/fonts";
// @import "@/assets/scss/global";
// @import "@/assets/scss/util";

#simulator {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  @include defFont;
  padding-top: spvw(25);

  :deep {
    * {
      box-sizing: border-box;
    }

    img,
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  
    a {
      text-decoration: none !important;
    }
  }
}

.sim-inner {
  position: relative;
  width: spvw(670);
  margin: 0 auto;

  @include onPC {
    width: pcvw(1140);
    display: flex;
    justify-content: space-between;
  }

  @include onWide {
    width: pcpx(1140);
  }
}

.sim__title {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: spvw(30);
  justify-content: center;
  align-items: center;

  @include onPC {
    width: pcperw(35, 1140);
    margin-bottom: 0;
  }
}

.sim__warning {
  position: relative;
  width: 100%;
  margin-top: spvw(30);

  @include onPC {
    width: pcperw(46, 1140);

    span {
      position: absolute;
      display: block;
      width: pcperw(510, 46);
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(-90deg);
    }
  }
}

</style>
