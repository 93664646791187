<template>
  <div ref="defPos">
    <a
      ref="item"
      class="sim-drag-item"
      :class="[
        `be--${param.index}`,
        {
          'is--dragging':isDragging,
          'is--fit': isFit
        }
      ]"
      :style="posStyle"

      @mousedown.prevent="dragStart"
      @touchstart.prevent="dragStart"
    >
      <slot />
    </a>
  </div>
</template>


<script>

export default {
  props:[
    'param', // {parts,index,label}
  ],
  data(){
    return {
      touchPos: {x:0, y:0}, // ドラッグ開始位置
      pos: {x:0, y:0},
      isDragging: false,
      isFit: false,
      fitTO: null,
      pressWaitTO: null,
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
    posStyle () {
      return {
        transform: `translate3d(${this.pos.x}px,${this.pos.y}px,0)`,
      }
    },
  },
  methods:{
    dragStart (event) {
      const mpos = this.getMousePos(event)
      this.touchPos.x = mpos.x;
      this.touchPos.y = mpos.y;
      window.addEventListener('mousemove', this.dragMove)
      window.addEventListener('mouseup', this.dragEnd)
      window.addEventListener('touchmove', this.dragMove)
      window.addEventListener('touchend', this.dragEnd)

      this.pressWaitTO = setTimeout(() => {
        this.isDragging = true
        this.$emit('drag-start')
      }, 300)
    },

    dragMove(event) {
      const mpos = this.getMousePos(event)
      if (this.isDragging) {
        // event.preventDefault()
        // event.stopPropagation()
        this.$emit('dragging')
        this.pos.x = mpos.x - this.touchPos.x; // Math.min(Math.max(0,x), rect.clientWidth)
        this.pos.y = mpos.y - this.touchPos.y; // Math.min(Math.max(0,y), rect.clientHeight)
      } else if (Math.abs(mpos.x - this.touchPos.x) > 10 && Math.abs(mpos.y - this.touchPos.y) < 10) {
        // 横スワイプしたらsplideのスライド操作として判定してドラッグ処理はリセット
        this.dragReset()
      }
    },

    dragEnd (event) {
      if (this.isDragging) {
        const mpos = this.getMousePos(event)
        this.$emit('drop-item', this, mpos)
        this.isDragging = false
      }
      this.dragReset()
    },

    dragReset() {
      clearTimeout(this.pressWaitTO)
      window.removeEventListener('mousemove', this.dragMove)
      window.removeEventListener('mouseup', this.dragEnd)
      window.removeEventListener('touchmove', this.dragMove)
      window.removeEventListener('touchend', this.dragEnd)
    },

    getMousePos (event) {
      let x,y
      const touches = event.changedTouches;
      if (touches) {
        x = touches[0].clientX;
        y = touches[0].clientY;
      } else {
        x = event.clientX;
        y = event.clientY;
      }
      return ({x,y})
    },

    /* 範囲内にドロップされたのでそれぞれの位置にフィットさせる */
    fit () {
      const areaRect = this.$store.state.viewerImage.getBoundingClientRect();
      const defPosRect = this.$refs.defPos.getBoundingClientRect();
      const fitPer = this.$store.state.fitPer[this.param.parts]
      this.pos.x = areaRect.x + areaRect.width * fitPer.x - defPosRect.x - defPosRect.width * 0.5;
      this.pos.y = areaRect.y + areaRect.height * fitPer.y - defPosRect.y - defPosRect.height * 0.5;
      this.isFit = true;

      clearTimeout(this.fitTO)
      this.fitTO = setTimeout(()=>{
        // this.return()
      }, 500)
    },

    /* 再表示 */
    return() {
      this.reset()
    },

    /* 位置戻す */
    reset () {
      this.pos.x = 0
      this.pos.y = 0
      this.isFit = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixin";
.sim-drag-item {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  pointer-events: visible;
  transition: opacity 0.6s $easeInOut;

  &__content {
    transition: transform 0.3s $easeOut;
  }
  :deep {
    .sim-drag-item__content {
      transition: transform 0.3s $easeOut;
    }
  }

  &.is--dragging {
    transition: none;
    z-index: 1;

    :deep {
      .sim-drag-item__content {
        filter: drop-shadow(3px 3px 5px rgba(#000, 0.5));
        transform: scale3d(1.2, 1.2, 1);
      }
    }
  }

  &.is--fit {
    z-index: 1;
    :deep {
      .sim-drag-item__content {
        transform: scale3d(0.9, 0.9, 1);
      }
    }
  }

  @include onPC {
    &:hover {
      cursor: grab;
    }

    &.is--dragging {
      cursor: grabbing;
    }
  }
}
</style>