<template>
  <div class="sim-viewer-effect" ref="world" >
  </div>
</template>

<script>
import * as PIXI from 'pixi.js'
import Star from '../class/EffectStar';

export default {
  props:[
  ],
  data(){
    return {
      app: null,
      textures: null,
      stars: [],
      starCnt: 20,
      lastTime: 0
    }
  },
  components: {
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this.init()
  },
  unmounted () {
  },
  methods:{
    init(){
      this.app = new PIXI.Application({
        width: 500,
        height: 500,
        antialiasing: true,
        transparent: true,
        resolution: 1
      });
      this.$refs.world.appendChild(this.app.view);
      this.textureLoad()
    },

    textureLoad(){
      const loader = new PIXI.Loader()
      loader
        .add("star", `${ this.$store.state.imgDir }viewer/effect-star.png`)
        .load(this.textureLoadEnd);
    },

    textureLoadEnd(loader, resources) {
      this.textures = {}

      for (let i in resources) {
        this.textures[i] = resources[i].texture
      }
      this.makeStars()
    },

    makeStars(){
      Star.init(this.textures.star)

      const stars = new PIXI.Container()
      this.app.stage.addChild(stars)
      stars.x = this.app.view.width / 2
      stars.y = this.app.view.height / 2

      this.stars = []
      
      for (let i = 0; i < this.starCnt; i++) {
        const star = new Star()
        stars.addChild(star)
        this.stars.push(star)        
      }
    },

    play() {
      const now = new Date()
      this.lastTime = now.getTime()

      for (let i = 0; i < this.stars.length; i++) {
        this.stars[i].play()
      }
      this.update()
    },

    update() {
      const now = new Date()
      const dlt = now.getTime() - this.lastTime
      this.lastTime = now.getTime()
      let doUpdate = false
      for (let i = 0; i < this.stars.length; i++) {
        const isUpdateComp = this.stars[i].update(dlt)
        if (!isUpdateComp) {
          doUpdate = true
        }
      }

      if(doUpdate) {
        requestAnimationFrame(this.update)
      } else {
        for (let i = 0; i < this.stars.length; i++) {
          this.stars[i].clear()
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixin";
.sim-viewer-effect {
  position: absolute;
  @include full;
  display: flex;
  justify-content: center;
  align-items: center;

  :deep{
    canvas {
      width: spvw(500);
      height: spvw(500);
    }
  }

  @include onPC {
    :deep {
      canvas {
        width: pcvw(500);
        height: pcvw(500);
      }
    }
  }

  @include onWide {
    :deep {
      canvas {
        width: pcpx(500);
        height: pcpx(500);
      }
    }
  }


}
</style>
