<template>
  <div class="sim__main" ref="simulator">
    <ViewerOrg ref="viewer"></ViewerOrg>
    <UIOrg ref="ui" @drop-item="dropItem"></UIOrg>
  </div>
</template>

<script>
import ViewerOrg from './ViewerOrg.vue'
import UIOrg from './UIOrg.vue'
import { mapMutations } from 'vuex'

export default {
  data(){
    return {
    }
  },
  setup() {
  },
  components: {
    ViewerOrg,
    UIOrg,
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  async mounted(){
    this.setDefaultParts()
  },
  unmounted(){
  },
  methods:{
    ...mapMutations(['updateParts']),

    setDefaultParts() {
      for(let i in this.$store.state.defaultParts) {
        this.setParts({parts:i, index:this.$store.state.defaultParts[i]})
      }
    },

    dropItem(item, dropPos) {
      const area = this.$refs.viewer.$el
      const areaRect = area.getBoundingClientRect();
      if (dropPos.x >= areaRect.x
        && dropPos.x <= areaRect.x + areaRect.width
        && dropPos.y >= areaRect.y
        && dropPos.y <= areaRect.y + areaRect.height
      ) {
        this.setParts(item.param)
        this.$refs.viewer.fit()
        item.fit()
      } else {
        item.reset()
      }
    },

    /**
     * @param {parts, index} 
     */
    setParts(param) {
      this.$refs.ui.updateItem(param)
      this.updateParts(param)

      if (param.index > 0) {
        // hands更新でright/leftをリセット
        if (param.parts === 'hands') {
          this.setParts({parts:'right',index:0})
          this.setParts({parts:'left',index:0})
        }
        // right/left更新でhandをリセット
        else if (param.parts === 'right') {
          if (this.$store.state.selectedParts.hands>0) {
            this.setParts({parts:'hands',index:0})
            this.setParts({parts:'left',index:1})
          }
        }
        else if (param.parts === 'left') {
          if (this.$store.state.selectedParts.hands>0) {
            this.setParts({parts:'hands',index:0})
            this.setParts({parts:'right',index:1})
          }
        }
      }
    },

  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixin";
.sim__main {
  position: relative;
  border: $primary spvw(8) solid;
  border-radius: spvw(50);
  background: url($imgDir+'viewer/grid.png') 50% 0 repeat;
  background-size: spvw(66);
  overflow: hidden;
  padding: spvw(40) 0 spvw(60);

  @include onPC {
    width: pcperw(1000, 1140);
    border: $primary pcvw(8) solid;
    border-radius: pcvw(30);
    background-size: pcvw(55);
    padding: pcperw(40, 1140) 0 pcperw(60, 1140);
  }

  @include onWide {
    border: $primary pcpx(8) solid;
    border-radius: pcpx(30);
    background-size: pcpx(55);
  }
}
</style>