<template>
  <div class="sim-viewer">
    <div class="sim-viewer__info">
      <div class="sim-viewer__name">
        <img :src="`${$store.state.imgDir}viewer/name.png`" alt="" />
      </div>
      <div class="sim-viewer__detail">
        <img :src="`${$store.state.imgDir}viewer/detail.png`" alt="" />
      </div>
    </div>
    <div class="sim-viewer__logos">
      <div class="sim-viewer__miku-logo">
        <img :src="`${$store.state.imgDir}viewer/logo-miku.png`" alt="" />
      </div>
      <div class="sim-viewer__nendoroid-logo">
        <img :src="`${$store.state.imgDir}viewer/logo-nendoroid.png`" alt="" />
      </div>
    </div>
    <div class="sim-viewer-image" ref="image">
      <div class="sim-viewer-image__image">
        <transition name="imageChange" mode="out-in">
          <img :src="imgSrc" alt="" :key="imgSrc" />
        </transition>
      </div>
    </div>
    <Effect ref="effect" />
  </div>
</template>

<script>
import Effect from "../molecules/FitEffectMol.vue";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      effectTO: null,
    };
  },
  components: {
    Effect,
  },
  computed: {
    imgSrc() {
      const parts = this.$store.state.selectedParts;
      return `${this.$store.state.imgDir}image/miku-${parts.face}-${parts.right}-${parts.left}-${parts.hands}.png`;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.setViewerImage(this.$refs.image);
  },
  unmounted() {},
  methods: {
    ...mapMutations(["setViewerImage", "updateParts"]),

    fit() {
      clearTimeout(this.effectTO);
      this.effectTO = setTimeout(() => {
        this.$refs.effect.play();
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixin";

.sim-viewer {
  position: relative;
  width: spvw(600);
  margin: 0 auto spvw(20);

  &__info {
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
    margin-bottom: spvw(20);
  }

  &__logos {
    position: absolute;
    right: 0;
    top: 0;
    width: spvw(120);
  }

  &__miku-logo {
    width: spvw(120);
    margin-bottom: 10%;
  }
  &__nendoroid-logo {
    width: spvw(120);
  }

  &__name {
    width: spvw(413);
    margin-bottom: spvw(20);
  }
  &__detail {
    width: spvw(177);
  }

  &-image {
    position: relative;
    width: 96%;
    margin: 0 auto;

    &__image {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 500 / 670 * 100%;
    }

    img {
      position: absolute;
      display: block;
      width: 100%;
      height: auto;
      left: 0;
      top: 0;

      &.imageChange-enter-active {
        animation: simImageEnter 0.3s $easeOut both;
      }

      &.imageChange-enter {
      }

      &.imageChange-enter-to {
      }

      &.imageChange-leave-active {
        transition: transform 0.1s $easeIn;
      }

      &.imageChange-leave {
      }

      &.imageChange-leave-to {
        transform: scale3d(0.5, 0.5, 1);
      }
    }
  }

  &__howto-btn {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: spvw(60);
    @include iconBtn;
  }

  @include onPC {
    width: pcperw(900, 1000);
    margin: 0 auto pcperw(40, 1000);

    &__info {
      width: pcperw(500, 900);
      margin-bottom: 0;
    }

    &__logos {
      left: 0;
      right: auto;
      top: auto;
      bottom: 0;
      width: pcperw(200, 900);
    }

    &__miku-logo {
      width: pcperw(120, 200);
    }

    &__nendoroid-logo {
      width: pcperw(160, 200);
    }

    &__name {
      width: pcperw(413, 500);
      margin-bottom: pcperw(20, 500);
    }
    &__detail {
      width: pcperw(177, 500);
    }

    &-image {
      position: relative;
      width: 62%;
      margin-top: -5%;
    }

    &__howto-btn {
      width: pcperw(40, 900);
    }
  }
}

@keyframes simImageEnter {
  0% {
    transform: scale3d(0.5, 0.5, 1);
  }
  40% {
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
</style>