<template>
  <div class="sim-ui-item" :class="{'is--selected':isSelected,}">
    <div class="sim-ui-item__placeholder">
      <img :src="imgSrc" alt="">
    </div>
    <p class="sim-ui-item__label">
      {{param.label}}
    </p>
    <DragItem :param="param" ref="dragItem" @drag-start="dragStart" @drop-item="dropItem" >
      <div class="sim-ui-item__content sim-drag-item__content">
        <img :src="imgSrc" alt="">
      </div>
    </DragItem>
    <div class="sim-ui-item__selected-icon"></div>
  </div>
</template>

<script>
import DragItem from './DragItemMol.vue'

export default {
  props:[
    'param', // {parts,index,label}
  ],
  data(){
    return {
    }
  },
  components: {
    DragItem
  },
  computed: {
    imgSrc () {
      return `${this.$store.state.imgDir}parts/${this.param.parts}-${this.param.index}.png`
    },

    isSelected () {
      return this.$store.state.selectedParts[this.param.parts] === this.param.index
    },
  },
  watch: {
    isSelected (val){
      if(!val) {
        this.$refs.dragItem.reset()
      }
    }
  },
  created () {
  },
  mounted () {
  },
  unmounted () {
  },
  methods:{

    dragStart() {
      this.$emit('drag-start');
    },

    dropItem(item, dropPos) {
      this.$emit('drop-item', item, dropPos);
    },
    
    select () {
    },

    return() {
      this.$refs.dragItem.reset()
    },

  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixin";
.sim-ui {
  &-item {
    position: relative;
    display: block;
    width: spvw(220*0.9);
    height: spvw(180*0.9);

    &__content {
      width: 100%;
      height: 100%;
    }

    &__placeholder {
      position: absolute;
      pointer-events: none;
      opacity: 0.3;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__label {
      position: absolute;
      width: spvw(150);
      height: spvw(30);
      left: 50%;
      bottom: 0;
      transform: translate3d(-50%, 150%, 0);
      background: $secondary;
      border-radius: spvw(15);
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: spvw(18);
      line-height: 1em;
      letter-spacing: -0.1em;
    }

    &__selected-icon {
      position: absolute;
      display: block;
      width: spvw(50);
      left: spvw(10);
      top: spvw(-14);
      opacity: 0;

      &::after {
        @include pseudo;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border-radius: 50%;
        background: url($imgDir+'viewer/icon-check.svg') 50% 50% no-repeat $primary;
        background-size: contain;
      }
    }

    &.is--selected {
      :deep {
        .sim-drag-item {
          pointer-events: none;
          transition: transform 0.2s $easeOut,
            opacity 0.05s linear 0.15s;
          opacity: 0;
        }
  
        .sim-ui-item__selected-icon {
          opacity: 1;
        }
      }

    }

    @include onPC {
      width: pcvw(220*0.8);
      height: pcvw(180*0.8);
  
      &__label {
        width: pcperw(150, 240 * 0.8);
        height: pcvw(30);
        border-radius: pcvw(15);
        font-size: pcvw(18);
      }
  
      &__selected-icon {
        width: pcperw(40, 240 * 0.8);
      }
  
    }

    @include onWide {
      width: pcpx(220*0.8);
      height: pcpx(180*0.8);
  
      &__label {
        height: pcpx(30);
        border-radius: pcpx(15);
        font-size: pcpx(18);
      }  
    }
  }

}
</style>
